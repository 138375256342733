import { thunk } from "easy-peasy";
import { Platform } from "react-native";
import {
  GetFileDetailAPICall,
  PutFileDetailAPICall,
  SelectDispatchAPICall,
  SelectFileAPICall,
  SelectWorkCenterAPICall,
  UploadOperationFilesAPICall,
} from "../../services";

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const thunks = {
  getFileDetail: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    actions.setModalLoading(true);

    try {
      const [productionOrderFiles, operationFiles] = await Promise.all([
        GetFileDetailAPICall(helpers.getState().selectedDispatch).then(
          (response) => response.data.data
        ),
        Promise.resolve(
          helpers.getState().selectedOperation.OperationID
            ? SelectDispatchAPICall({
                ProductionNbr: helpers.getState().selectedDispatch,
              })
                .then(
                  (response) =>
                    response.data.data[0].Operations.find(
                      (op) =>
                        op.OperationNbr.value ===
                        helpers.getState().selectedOperation.OperationID
                    ).files
                )
                .catch(() => [])
            : []
        ),
      ]);

      actions.setFileList(productionOrderFiles);
      actions.setOperationFileList(operationFiles);
      actions.setModalLoading(false);
    } catch (error) {}

    actions.setLoadingAction(false);
    actions.setModalLoading(false);
  }),

  upsertOperationNoteThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    actions.setModalLoading(true);

    try {
      await PutFileDetailAPICall({
        id: helpers.getState().selectedOperation.WONoteID,
        ProductionNbr: {
          value: helpers.getState().selectedOperation.ProductionNbr,
        },
        Operation: {
          id: helpers.getState().selectedOperation.OpNoteID,
          note: { value: helpers.getState().docs.updatedDocNote },
          OperationNbr: {
            value: helpers.getState().selectedOperation.OperationID,
          },
        },
        Operations: [
          {
            id: helpers.getState().selectedOperation.OpNoteID,
            note: { value: helpers.getState().docs.updatedDocNote },
            OperationNbr: {
              value: helpers.getState().selectedOperation.OperationID,
            },
          },
        ],
      });

      actions.setModalLoading(false);
      actions.setAlertThunk({
        type: "success",
        message: "Note uploaded successfully",
      });
      actions.setUpdatedDocNoteModalVisibleAction(false);

      const workCenterDispatchListResponse = await SelectWorkCenterAPICall({
        WorkCenter: helpers.getState().desiredWorkCenter,
      });
      await actions.setWorkCenterDispatch(
        workCenterDispatchListResponse.data.data
      );
    } catch (error) {
      console.log(error);
      actions.setAlertThunk({
        type: "success",
        message: "There was an error uploading the file. Please try again.",
      });
      //handleError(error);
    }
    actions.setLoadingAction(false);
    actions.setModalLoading(false);
  }),

  upsertOperationFilesThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    actions.setModalLoading(true);

    try {
      await UploadOperationFilesAPICall({
        guid: helpers.getState().selectedOperation.OpNoteID,
        fileName: payload.name,
        file: payload.uri,
      });

      actions.setModalLoading(false);
      actions.setAlertThunk({
        type: "success",

        message: "File uploaded successfully",
      });
    } catch (error) {
      actions.setAlertThunk({
        type: "success",

        message: "There was an error uploading the file. Please try again.",
      });
      console.log(error);
      //handleError(error);
    }
    actions.setLoadingAction(false);
    actions.setModalLoading(false);
  }),

  selectFile: thunk(async (actions, payload, helpers) => {
    const response = await SelectFileAPICall(payload.id);
    if (response.status === 200) {
      if (response.data.data !== "") {
        if (payload.item.filename && payload.item.filename.includes(".pdf")) {
          let blob = b64toBlob(response.data.data, "application/pdf");
          if (Platform.OS === "web") {
            let url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          } else {
            // let url = URL.createObjectURL(blob);
            let base64Prefix = "data:application/pdf;base64,";
            actions.setWebViewURL(base64Prefix + response.data.data);
            //  actions.setWebViewURL(url)
            actions.setWebViewActive(true);
          }
        } else if (
          payload.item.filename &&
          payload.item.filename.includes(".PDF")
        ) {
          let blob = b64toBlob(response.data.data, "application/pdf");
          if (Platform.OS === "web") {
            let url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          } else {
            // let url = URL.createObjectURL(blob);
            let base64Prefix = "data:application/pdf;base64,";
            actions.setWebViewURL(base64Prefix + response.data.data);
            //  actions.setWebViewURL(url)
            actions.setWebViewActive(true);
          }
          // let url = URL.createObjectURL(blob);
          // window.open(url, '_blank');
        } else if (
          payload.item.filename &&
          payload.item.filename.includes(".jpg")
        ) {
          const blob = b64toBlob(response.data.data, "image/jpeg");
          if (Platform.OS === "web") {
            let url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          } else {
            // let url = URL.createObjectURL(blob);
            let base64Prefix = "data:image/jpeg;base64,";
            actions.setWebViewURL(base64Prefix + response.data.data);
            //  actions.setWebViewURL(url)
            actions.setWebViewActive(true);
          }
          // const url = URL.createObjectURL(blob);
          // window.open(url, '_blank');
        } else if (
          payload.item.filename &&
          payload.item.filename.includes(".png")
        ) {
          const blob = b64toBlob(response.data.data, "image/png");
          if (Platform.OS === "web") {
            let url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          } else {
            // let url = URL.createObjectURL(blob);
            let base64Prefix = "data:image/jpeg;base64,";
            actions.setWebViewURL(base64Prefix + response.data.data);
            //  actions.setWebViewURL(url)
            actions.setWebViewActive(true);
          }
          // const url = URL.createObjectURL(blob);
          // window.open(url, '_blank');
        } else if (
          payload.item.filename &&
          payload.item.filename.includes(".xlsx")
        ) {
          const blob = b64toBlob(
            response.data.data,
            "application/octet-stream"
          );
          const url = URL.createObjectURL(blob);

          actions.setExcelUrl(url);
        } else if (
          payload.item.filename &&
          payload.item.filename.includes(".docx")
        ) {
          const blob = b64toBlob(
            response.data.data,
            "application/octet-stream"
          );
          const url = URL.createObjectURL(blob);

          await actions.setDocUrl(url);
        }
      }
    }
  }),
};
export default thunks;
