import styled from "styled-components/native";
import TableContext from "./TableContext";
import React from "react";
import Div from "../Div";

const Container = styled(Div)`
  width: 100%;
`;

function Table({
  children,
  striped = true,
  border = true,
  hover = true,
  ...props
}) {
  return (
    <TableContext.Provider
      value={{
        striped,
        hover,
        border,
      }}
    >
      <Container {...props}>{children}</Container>
    </TableContext.Provider>
  );
}

export default Table;
