import styled from "rn-css";
import Row from "../Row";
import React, { useContext } from "react";
import TableContext from "./TableContext";

const Container = styled(Row)`
  width: 100%;
  background-color: ${(props) => (props.striped ? "#D0E1FC" : "#fff")};

  &:hover {
    background-color: ${(props) => props.hover && "#D0E1FC"};
  }
`;

function Tr({ children, striped = false, ...props }) {
  const context = useContext(TableContext);
  return (
    <Container
      striped={context.striped && striped}
      hover={context.hover}
      {...props}
    >
      {children}
    </Container>
  );
}

export default Tr;
