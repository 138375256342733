import React from "react";
import SelectDropdown from "react-native-select-dropdown";
import styled from "styled-components/native";
import Text from "./Text";
import { Input as RNInput } from "@rneui/themed";
import { MaterialIcons } from "@expo/vector-icons";
import { TouchableOpacity, View, Platform } from "react-native";
import { remToDp } from "../../utilities/responsive";

const SInput = styled(RNInput)`
  background-color: ${(props) => (props.editable ? "none" : "lightgray")};
  padding: 0 10px;
  width: 100%;
  color: #00205b;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  background-color: ${(props) => (props.editable ? "none" : "lightgray")};
  padding: 0 10px;
  width: 100%;
  color: #00205b;
`;

const Label = styled(Text)`
  color: #00205b;
  padding: 0 10px;
`;

const RequiredLabel = styled(Label)`
  color: #ab2328;
`;

const SearchIcon = styled(MaterialIcons).attrs({
  name: "search",
  size: 20,
  color: "#00205b",
  alignSelf: "center",
})`
  margin-left: 10px;
`;

function Input({
  value,
  label,
  required = false,
  dropdown = false,
  dropdownChoices,
  rowTextForSelection,
  selectedItem,
  onSelect,
  width = "98%",
  ...props
}) {
  return (
    <>
      {label && (
        <Label style={{ fontSize: remToDp(1) }}>
          {label} {required && <RequiredLabel>*</RequiredLabel>}
        </Label>
      )}
      {dropdown ? (
        <StyledSelectDropdown
          key={value}
          {...props}
          rowTextForSelection={(item, index) => rowTextForSelection(item)}
          defaultButtonText={value == "" ? "Select" : value}
          value={value}
          selectedItem={value}
          data={dropdownChoices}
          onSelect={(value) => onSelect(value)}
          buttonTextAfterSelection={(item, index) => selectedItem(item)}
          buttonStyle={{
            backgroundColor:
              props.editable && props.editable == true ? "none" : "lightgray",
            width: width,
            alignSelf: "center",
            alignContent: "flex-start",
            alignText: "flex-start",
            alignItems: "center",
            justifyContent: "center",
            color: "#00205b",
            borderBottomWidth: Platform.OS == "web" ? 0.1 : 0.3,

            paddingLeft: 0,
            borderColor: "#00205b",
            marginBottom: Platform.OS == "web" ? 10 : 25,
            height: 41,
          }}
          buttonTextStyle={{
            color: value == "" ? "gray" : "#00205b",
            fontSize: 17,
            flexDirection: "row",
            textAlign: "left",
            alignText: "left",
            padding: Platform.OS == "web" ? 10 : 0,
            alignSelf: "center",
          }}
          rowStyle={{ backgroundColor: "white" }}
          rowTextStyle={{ color: "#00205b", padding: 15 }}
          dropdownStyle={
            props.dropdownStyle
              ? props.dropdownStyle
              : {
                  justifyContent: "space-evenly",
                  backgroundColor: "lightgray",
                  alignSelf: "center",
                }
          }
          dropdownTextStyle={{
            color: "#00205b",
          }}
          renderDropdownIcon={() => <SearchIcon />}
          disabled={!props.editable}
        />
      ) : (
        <SInput
          value={value}
          placeholderTextColor="#76787A"
          editable={true}
          {...props}
          rightIconContainerStyle={{ position: "absolute", right: 0 }}
          rightIcon={
            props.number ? (
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    props.onChangeText((parseInt(props.value) + 1).toString());
                  }}
                >
                  <MaterialIcons
                    name="keyboard-arrow-up"
                    size={20}
                    color="#00205b"
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    props.onChangeText((parseInt(props.value) - 1).toString());
                  }}
                >
                  <MaterialIcons
                    name="keyboard-arrow-down"
                    size={20}
                    color="#00205b"
                  />
                </TouchableOpacity>
              </View>
            ) : props.rightIcon ? (
              props.rightIcon
            ) : null
          }
        />
      )}
    </>
  );
}

export default Input;
