import { action } from "easy-peasy";

const actions = {
  setFileList: action((state, payload) => {
    state.docs.fileList = payload;
  }),
  setOperationFileList: action((state, payload) => {
    state.docs.operationFileList = payload;
  }),
  setDocUrl: action((state, payload) => {
    state.docs.docUrl = payload;
  }),
  setExcelUrl: action((state, payload) => {
    state.docs.excelUrl = payload;
  }),
  setWebViewActive: action((state, payload) => {
    state.docs.webViewActive = payload;
  }),
  setWebViewURL: action((state, payload) => {
    state.docs.webViewURL = payload;
  }),
  setUpdatedDocNote: action((state, payload) => {
    state.docs.updatedDocNote = payload;
  }),

  setUpdatedDocNoteModalVisibleAction: action((state, payload) => {
    state.docs.updatedDocNoteModalVisible = payload;
  }),
};

export default actions;
