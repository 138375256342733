import { useStoreActions, useStoreState } from "easy-peasy";
import React from "react";
import { Dimensions, View, TouchableOpacity } from "react-native";
import SelectDropdown from "react-native-select-dropdown";
import DynamicText from "../../presentational/MyText";
import { AntDesign } from "@expo/vector-icons";
import { useResponsiveDropdownSize } from "../../../hooks/responsive";

const { height } = Dimensions.get("window");

const WorkCenterCard = () => {
  const { workCenters, desiredWorkCenter, workCenterString } = useStoreState(
    (state) => state
  );
  const {
    selectWorkCenterThunk,
    getWorkCentersThunk,
    setDesiredWorkCenter,
    setWorkCenterString,
  } = useStoreActions((actions) => actions);

  const { responsiveDropdownItemTextSize, responsiveDropdownChosenTextSize } =
    useResponsiveDropdownSize();

  return (
    <>
      <View
        style={{
          ...styles.cardView,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <DynamicText text={"Work Center"} fontSize={12} />
        <SelectDropdown
          onFocus={() => {
            getWorkCentersThunk();
          }}
          defaultButtonText={
            workCenterString !== "" ? workCenterString : "Select Work Center"
          }
          selectedItem={desiredWorkCenter}
          disableAutoScroll={true}
          value={desiredWorkCenter}
          data={workCenters}
          onSelect={async (selectedItem) => {
            setWorkCenterString(
              `${selectedItem.WorkCenter} - ${selectedItem.Description}`
            );
            setDesiredWorkCenter(selectedItem.WorkCenter);
            selectWorkCenterThunk(selectedItem.WorkCenter);
          }}
          buttonTextAfterSelection={(selectedItem) =>
            `${selectedItem.WorkCenter} - ${selectedItem.Description}`
          }
          rowTextForSelection={(item) =>
            `${item.WorkCenter} - ${item.Description}`
          }
          buttonTextStyle={{
            fontSize: responsiveDropdownChosenTextSize,
            textAlign: "flex-start",
            width: "20%",
          }}
          buttonStyle={{
            width: "25%",
            marginLeft: "1%",
            borderRadius: 10,
          }}
          rowTextStyle={{
            fontSize: responsiveDropdownItemTextSize,
            textAlign: "flex-start",
            width: "100%",
            padding: 20,
          }}
          search
        />
        <TouchableOpacity>
          <AntDesign
            name="reload1"
            size={24}
            color="black"
            style={{
              marginLeft: 10,
            }}
            onPress={() => selectWorkCenterThunk(desiredWorkCenter)}
          />
        </TouchableOpacity>
      </View>
    </>
  );
};

export default WorkCenterCard;

const styles = {
  cardView: {
    alignSelf: "center",
    alignContent: "flex-start",
    width: "92%",
  },
};
