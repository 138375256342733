import React from "react";
import CustomModal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useResponsiveModalSize } from "../../../hooks/responsive";
import StepsList from "../List/StepsList";

const StepListModal = () => {
  const { stepListModalVisible } = useStoreState((state) => state);

  const { setStepListModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  const { responsiveModalWidth, responsiveModalHeight } =
    useResponsiveModalSize();

  return (
    <CustomModal
      isVisible={stepListModalVisible}
      onClose={() => setStepListModalVisibleAction(false)}
      title="Step"
      style={{ width: responsiveModalWidth }}
    >
      <StepsList isShownInModal maxHeight={responsiveModalHeight} />
    </CustomModal>
  );
};

export default StepListModal;
