import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";
import Row from "../../presentational/Row";
import Button from "../../presentational/Button";
import { useResponsiveModalSize } from "../../../hooks/responsive";

const MoveModal = () => {
  const {
    move: { moveModalVisible, input },
    selectedOperation,
    user,
  } = useStoreState((state) => state);

  const { createMoveThunk, setMoveModalVisible, setMoveInputAction } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    setMoveInputAction({
      Description: {
        value: "",
      },
      ControlQty: {
        value: 0,
      },
      Details: [
        {
          ...input.Details[0],
          Quantity: {
            value: 0,
          },
          QtyScrapped: {
            value: 0,
          },
        },
      ],

      TotalQty: {
        value: 0,
      },
    });
  }, [moveModalVisible]);

  const canCreateMove =
    (user.kioskConfig.checkApprovals && selectedOperation.LaborCanMove) ||
    !user.kioskConfig.checkApprovals;

  const { responsiveModalWidth } = useResponsiveModalSize();

  return (
    <CustomModal
      onClose={() => setMoveModalVisible(false)}
      isVisible={moveModalVisible}
      title="Move"
      style={{ width: responsiveModalWidth }}
    >
      <Row>
        <Col xs={12} md={6}>
          <Input
            placeholder="Type in Production Number"
            label="Production Number"
            value={selectedOperation.ProductionNbr}
            editable={false}
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            placeholder="Type in Operation Number"
            label="Operation Number"
            value={selectedOperation.OperationID}
            editable={false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={2.4}>
          <Input
            placeholder="Type in Work Center"
            label="Work Center"
            value={selectedOperation && selectedOperation.WorkCenter}
            editable={false}
          />
        </Col>

        <Col xs={12} lg={2.4}>
          <Input
            placeholder="Move Qty"
            label="Move Qty"
            keyboardType="numeric"
            value={input.ControlQty.value}
            onChangeText={(ControlQty) => {
              if (/^-?\d*$/.test(ControlQty) || ControlQty === "") {
                setMoveInputAction({
                  ControlQty: {
                    value: ControlQty,
                  },
                  Details: [
                    {
                      ...input.Details[0],
                      Quantity: {
                        value: ControlQty,
                      },
                    },
                  ],
                  TotalQty: {
                    value: ControlQty,
                  },
                });
              }
            }}
          />
        </Col>
        <Col xs={12} lg={2.4}>
          <Input
            placeholder="Qty Complete"
            label="Qty Complete"
            value={`${parseInt(selectedOperation.QtyComplete)}`}
            onChangeText={(ControlQty) =>
              setMoveInputAction({
                ControlQty: {
                  value: ControlQty,
                },
                Details: [
                  {
                    ...input.Details[0],
                    Quantity: {
                      value: ControlQty,
                    },
                  },
                ],

                TotalQty: {
                  value: ControlQty,
                },
              })
            }
            editable={false}
          />
        </Col>
        <Col xs={12} lg={2.4}>
          <Input
            placeholder="Qty Remaining"
            label="Qty Remaining"
            value={`${parseInt(selectedOperation.QtyRemaining)}`}
            editable={false}
            onChangeText={(ControlQty) =>
              setMoveInputAction({
                Details: [
                  {
                    ...input.Details[0],
                    Quantity: {
                      value: ControlQty,
                    },
                  },
                ],
              })
            }
          />
        </Col>
        <Col xs={12} lg={2.4}>
          <Input
            placeholder="Qty Scrapped"
            label="Qty Scrapped"
            value={input.Details[0].QtyScrapped.value}
            editable={false}
            onChangeText={(QtyScrapped) => {
              if (/^-?\d*$/.test(QtyScrapped) || QtyScrapped === "") {
                setMoveInputAction({
                  Details: [
                    {
                      ...input.Details[0],
                      QtyScrapped: {
                        value: QtyScrapped,
                      },
                    },
                  ],
                });
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            placeholder="Type in Description"
            label="Description"
            value={input.Description.value}
            onChangeText={(description) =>
              setMoveInputAction({ Description: { value: description } })
            }
          />
        </Col>
      </Row>
      <Col>
        <Button
          fullWidth
          disabled={!canCreateMove}
          onPress={() => createMoveThunk()}
        >
          Create Move
        </Button>
      </Col>
    </CustomModal>
  );
};

export default MoveModal;
