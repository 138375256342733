import React from "react";
import { Entypo, Ionicons } from "@expo/vector-icons";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import Input from "../../presentational/Input";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Button from "../../presentational/Button";
import Icon from "../../presentational/Icon";
import CustomModal from "../../presentational/Modal";
import TaskDatePicker from "../DatePicker/TaskDatePicker";
import { useResponsiveUnits } from "../../../utilities/responsive";

const TaskModal = () => {
  const {
    employees,
    opNbr,
    task: { input, taskModalVisible, datePickerVisible },
    selectedDispatch,
  } = useStoreState((state) => state);

  const {
    setTaskModalVisible,
    setTaskSubject,
    setDatePickerVisible,
    putTaskThunk,
    setTaskInputAction,
  } = useStoreActions((actions) => actions);

  const { vw } = useResponsiveUnits();

  return (
    <CustomModal
      onClose={() => {
        setTaskModalVisible(false);
      }}
      isVisible={taskModalVisible}
      style={{ width: vw(50) }}
      width="50%"
      height="50%"
      title="Task Entry"
    >
      {datePickerVisible && <TaskDatePicker />}

      <Row>
        <Col xs={12} md={6}>
          <Input
            placeholder="Type in Owner"
            label="Owner"
            onSelect={(selectedItem) => {
              setTaskInputAction({
                Owner: { value: selectedItem.ContactID },
                RelatedEntityDescription: {
                  value:
                    selectedDispatch +
                    ":" +
                    opNbr +
                    " - " +
                    selectedItem.LegalName,
                },
              });
            }}
            buttonTextAfterSelection={(selectedItem, index) => {
              return selectedItem.EmployeeID;
            }}
            rowTextForSelection={(item, index) => {
              return item.EmployeeID + " - " + item.LegalName;
            }}
            editable={true}
            selectedItem={(item) => item.EmployeeID + " - " + item.LegalName}
            dropdown
            dropdownChoices={employees}
            width={"100%"}
            search={true}
            searchInputStyle={{
              width: "100%",
              alignSelf: "center",
              backgroundColor: "#F7F7F7",
              border: 0,

              boxShadow: "0px 0px 10px grey",
              borderColor: "#F7F7F7",
            }}
            searchInputTxtStyle={{
              fontFamily: "Barlow_400Regular",
              fontSize: 16,
            }}
            renderSearchInputRightIcon={() => (
              <Icon Component={Entypo} name="magnifying-glass" />
            )}
            searchInputPlaceholder="Search for Tool Part Number"
            dropdownStyle={{
              backgroundColor: "white",
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            placeholder="Type in Priority"
            label="Priority"
            onSelect={(selectedItem, index) => {
              setTaskInputAction({ Priority: { value: selectedItem.label } });
            }}
            editable={true}
            selectedItem={(item) => item.label}
            buttonTextAfterSelection={(selectedItem, index) => {
              return selectedItem.label;
            }}
            rowTextForSelection={(item, index) => {
              return item.label;
            }}
            dropdownStyle={{
              height: 60 * 3,
              backgroundColor: "white",
            }}
            dropdown
            dropdownChoices={[
              { value: "low", label: "Low" },
              { value: "normal", label: "Normal" },
              { value: "high", label: "High" },
            ]}
            width={"100%"}
          />
        </Col>
      </Row>
      <Col>
        <Input
          placeholder="Type in Description"
          label="Description"
          value={input.Body.value}
          editable={true}
          onChangeText={(text) => {
            setTaskInputAction({
              Body: { value: text },
            });
          }}
        />
      </Col>
      <Row>
        <Col xs={12} md={6}>
          <Input
            placeholder="Type in Due Date"
            label="Due Date"
            value={moment(input.DueDate.value).format("MM/DD/YYYY")}
            onClick={() => {
              setDatePickerVisible(true);
            }}
            rightIcon={<Ionicons name="calendar" size={20} color="#00205b" />}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            placeholder="Type in Related Entry"
            label="Related Entry"
            value={input.RelatedEntityDescription.value}
            editable={false}
            onChangeText={(text) => {
              setTaskSubject({
                RelatedEntityDescription: { value: text },
              });
            }}
          />
        </Col>
      </Row>
      <Col>
        <Button
          fullWidth
          onPress={() => {
            putTaskThunk();
          }}
        >
          Create Task
        </Button>
      </Col>
    </CustomModal>
  );
};

export default TaskModal;
