import { useWindowDimensions } from "react-native";
import { useResponsiveUnits } from "../utilities/responsive";

function useResponsiveTableSize() {
  const { vw } = useResponsiveUnits();
  const { width } = useWindowDimensions();
  let headerSize = vw(0.95);
  let bodySize = vw(0.9);
  let iconSize = vw(1.5);

  if (width <= 1200) {
    headerSize = vw(1.25);
    bodySize = vw(1);
    iconSize = vw(1.75);
  } else if (width <= 992) {
    headerSize = vw(2);
    bodySize = vw(1.75);
    iconSize = vw(2);
  } else if (width <= 768) {
    headerSize = vw(3);
    bodySize = vw(2.25);
    iconSize = vw(2.5);
  }

  return {
    responsiveTableHeaderSize: headerSize,
    responsiveTableBodySize: bodySize,
    responsiveTableIconSize: iconSize,
  };
}

function useResponsiveModalSize() {
  const { vw, vh } = useResponsiveUnits();
  return { responsiveModalWidth: vw(80), responsiveModalHeight: vh(65) };
}

function useResponsiveDropdownSize() {
  const { vw } = useResponsiveUnits();
  const { width } = useWindowDimensions();
  let itemTextSize = vw(0.9);
  let chosenItemTextSize = vw(0.95);

  if (width <= 1200) {
    itemTextSize = vw(1.25);
    chosenItemTextSize = vw(1.3);
  } else if (width <= 992) {
    itemTextSize = vw(2);
    chosenItemTextSize = vw(2.3);
  } else if (width <= 768) {
    itemTextSize = vw(3);
    chosenItemTextSize = vw(3.3);
  }

  return {
    responsiveDropdownItemTextSize: itemTextSize,
    responsiveDropdownChosenTextSize: chosenItemTextSize,
  };
}

export {
  useResponsiveTableSize,
  useResponsiveModalSize,
  useResponsiveDropdownSize,
};
