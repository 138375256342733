import React from "react";
import CustomModal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import ProductionOrderList from "../List/ProductionOrderList";
import SearchBar from "../../presentational/SearchBar";
import Gap from "../../presentational/Gap";
import { useResponsiveUnits } from "../../../utilities/responsive";

const DispatchListModal = () => {
  const { dispatchListModalVisible, productionOrderSearchInput } =
    useStoreState((state) => state);

  const {
    setDispatchListModalVisibleAction,
    setProductionOrderSearchInputAction,
  } = useStoreActions((actions) => actions);

  const { vw, vh } = useResponsiveUnits();

  return (
    <CustomModal
      isVisible={dispatchListModalVisible}
      onClose={() => {
        setDispatchListModalVisibleAction(false);
        setProductionOrderSearchInputAction("");
      }}
      title="Production Orders"
      style={{ width: vw(80) }}
    >
      <SearchBar
        value={productionOrderSearchInput}
        onChangeText={(input) => setProductionOrderSearchInputAction(input)}
        placeholder="Search by Production Number, Description, Inventory ID"
      />
      <Gap />
      <ProductionOrderList isShownInModal={true} maxHeight={vh(65)} />
    </CustomModal>
  );
};

export default DispatchListModal;
