import { useState, useEffect } from "react";
import {
  ActivityIndicator,
  Dimensions,
  Image,
  TouchableOpacity,
  View,
} from "react-native";
import { StatusBar } from "expo-status-bar";
import { useStoreActions, useStoreState } from "easy-peasy";
import Text from "./Text.js";
import Center from "./Center/index.js";
import Message from "./Message";

function Page({ children, ...props }) {
  const {
    status: { alerts, loading },
    user,
    userIsLoggedIn,
    acumaticaIsLoggedIn,
    desiredWorkCenter,
  } = useStoreState((state) => state);

  const { selectWorkCenterThunk } = useStoreActions((actions) => actions);

  const [showMessage, setShowMessage] = useState(false);
  const [loadingStartTime, setLoadingStartTime] = useState(null);

  useEffect(() => {
    if (loading) {
      setLoadingStartTime(new Date());
    } else {
      setLoadingStartTime(null);
      setShowMessage(false);
    }
  }, [loading]);

  useEffect(() => {
    let timer;
    if (loadingStartTime) {
      timer = setTimeout(() => {
        setShowMessage(true);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [loadingStartTime]);

  return (
    <>
      {userIsLoggedIn && acumaticaIsLoggedIn && (
        <TouchableOpacity
          onPress={() => selectWorkCenterThunk(desiredWorkCenter)}
          style={{
            position: "absolute",
            zIndex: 13,
            top: 0,
            right: "1%",
            width: "15%",
            height: "15%",
          }}
        >
          <Image
            style={{
              resizeMode: "contain",
              width: "100%",
              height: "100%",
            }}
            source={`https://kioskbranding.blob.core.windows.net/images/${
              user && user.logo
            }`}
          />
        </TouchableOpacity>
      )}
      <View {...props}>
        <StatusBar style="auto" />

        {alerts.map(({ type, ...alert }, index) => {
          let Component = Message;
          if (type === "error") Component = Message.Error;
          else if (type === "success") Component = Message.Success;
          return <Component key={index}>{alert.message}</Component>;
        })}

        {loading ? (
          <Center.Screen>
            {showMessage && (
              <Text
                style={{
                  fontSize: 30,
                  position: "absolute",
                  top: "35%",
                }}
              >
                {user.kioskConfig?.loadingMessage || "Processing..."}
              </Text>
            )}
            <ActivityIndicator
              size="large"
              style={{
                alignSelf: "center",
                position: "absolute",
                top: Dimensions.get("window").height / 2 - 70,
              }}
            />
          </Center.Screen>
        ) : (
          children
        )}
      </View>
    </>
  );
}

export default Page;
