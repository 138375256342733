import { useState } from "react";
import { AntDesign } from "@expo/vector-icons";
import Table from "./Table";
import { ActivityIndicator, TouchableOpacity, View } from "react-native";
import { StyleSheet } from "react-native";
import { ScrollView } from "react-native";
import { height } from "../../utilities/general";
import Text from "./Text";
import styled from "styled-components/native";
import Center from "./Center";
import Div from "./Div";
import { useResponsiveTableSize } from "../../hooks/responsive";

const Header = styled(Table.th)`
  flex: auto;
  width: ${(props) => props.width};
  flex-wrap: nowrap;
  height: ${(props) => props.height};
`;
Header.NameContainer = styled(Center.V2)`
  height: 100%;
  padding: 8px;
`;
Header.Name = styled(Div)`
  font-family: Helvetica Neue;
  text-align: center;
  font-size: ${(props) => props.fontSize};
  color: #00205b;
`;

const Cell = styled(Table.td)`
  flex: auto;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
Cell.ValueContainer = styled(Center.V2)`
  width: 100%;
  height: 100%;
  padding: 16px;
  border-color: #dee2e6;
  text-align: center;
`;
Cell.Value = styled(Div)`
  font-family: Helvetica Neue;
  color: #00205b;
  font-size: ${(props) => props.fontSize};
`;

function List({
  tableHead = [],
  tableRows = [],
  striped = true,
  hover = true,
  border = true,
  pageSize = 10,
  loading = false,
  selectItem = -1,
  listHeight = 6.5,
  maxHeight = 5.5,
  pagination = false,
  doNotScroll = true,
  fontSize = 14,
  isShownInModal,
  width = [],
  list = [],
  onSelectRowInPopup = () => () => {},
  onPressCellEvents,
  scrollViewRef,
  itemRef,
  ...props
}) {
  const { responsiveTableHeaderSize, responsiveTableBodySize } =
    useResponsiveTableSize();

  const [currentPage, setCurrentPage] = useState(0);

  const totalPages = Math.ceil(tableRows.length / pageSize);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedRows = tableRows.slice(startIndex, endIndex);
  if (pagination == true) {
    tableRows = paginatedRows;
  }

  return (
    <>
      {loading ? (
        <ScrollView contentContainerStyle={styles.tbodyScrollable1}>
          <ActivityIndicator
            style={{
              alignSelf: "center",
            }}
            size={35}
          />
        </ScrollView>
      ) : (
        <>
          <Table {...props}>
            <Table.tr>
              {tableHead.map((children, index) => (
                <Header
                  key={index}
                  width={width[index] || `${100 / tableHead.length}%`}
                >
                  <Header.NameContainer>
                    <Header.Name fontSize={`${responsiveTableHeaderSize}px`}>
                      {children}
                    </Header.Name>
                  </Header.NameContainer>
                </Header>
              ))}
            </Table.tr>
          </Table>
          <ScrollView
            style={
              isShownInModal
                ? {
                    width: "100%",
                    maxHeight,
                  }
                : {
                    width: "100%",
                    height: height / listHeight,
                    maxHeight: height / maxHeight, // Adjust this value to your needs
                    overflow: "auto",
                  }
            }
            contentContainerStyle={{ width: "100%" }}
            showsVerticalScrollIndicator={false}
            ref={scrollViewRef}
          >
            <Table {...props}>
              {tableRows.map((row, rindex) => (
                <TouchableOpacity
                  onPress={
                    onSelectRowInPopup && isShownInModal
                      ? onSelectRowInPopup(list[rindex])
                      : null
                  }
                >
                  <Table.tr key={rindex} striped={rindex % 2 === 1}>
                    {row.map((children, index) => (
                      <Cell
                        key={index}
                        width={width[index] || `${100 / tableHead.length}%`}
                        style={{
                          backgroundColor:
                            rindex % 2 === 0 && selectItem !== rindex
                              ? "#fff"
                              : selectItem == rindex
                              ? "#D0E1FC"
                              : "#F7F7F7",
                          border,
                        }}
                      >
                        <TouchableOpacity
                          ref={itemRef ? itemRef(list[rindex]) : null}
                          onPress={
                            !isShownInModal &&
                            onPressCellEvents &&
                            onPressCellEvents[index]
                              ? onPressCellEvents[index](list[rindex])
                              : onSelectRowInPopup(list[rindex])
                          }
                          style={{ width: "100%", height: "100%" }}
                        >
                          <Cell.ValueContainer>
                            <Cell.Value
                              numberOfLines={1}
                              style={{
                                color:
                                  children === "Inactive" ||
                                  children === "CO" ||
                                  children === "Unavailable" ||
                                  children == "Broken Tool" ||
                                  children == "Unserviceable" ||
                                  children == "Checked Out"
                                    ? "red"
                                    : children === "Active" ||
                                      children === "Available" ||
                                      children === "Configured"
                                    ? "green"
                                    : children === "Reserved" ||
                                      children === "Inspection"
                                    ? "#c0b000"
                                    : "#00205b",
                                fontSize: responsiveTableBodySize,
                              }}
                            >
                              {children}
                            </Cell.Value>
                          </Cell.ValueContainer>
                        </TouchableOpacity>
                      </Cell>
                    ))}
                  </Table.tr>
                </TouchableOpacity>
              ))}
            </Table>
          </ScrollView>
        </>
      )}
      {pagination && (
        <View style={styles.paginationContainer}>
          <TouchableOpacity
            onPress={handlePrevPage}
            disabled={currentPage === 0}
          >
            <AntDesign
              name="left"
              size={20}
              color={currentPage === 0 ? "gray" : "black"}
            />
          </TouchableOpacity>
          <Text style={styles.pageInfo}>
            Page {currentPage + 1} of {totalPages}
          </Text>
          <TouchableOpacity
            onPress={handleNextPage}
            disabled={currentPage >= totalPages - 1}
          >
            <AntDesign
              name="right"
              size={20}
              color={currentPage >= totalPages - 1 ? "gray" : "black"}
            />
          </TouchableOpacity>
        </View>
      )}
    </>
  );
}

export default List;

const styles = StyleSheet.create({
  tbodyScrollable: {
    width: "100%",
    height: height / 6.5,
    maxHeight: height / 5.5, // Adjust this value to your needs
    overflow: "auto",
  },

  tbodyScrollable1: {
    width: "100%",
    height: 180,
    maxHeight: 180, // Adjust this value to your needs
    overflow: "auto",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  paginationContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 10,
    bottom: 0,
    right: 0,
  },
  pageInfo: {
    marginHorizontal: 10,
  },
});
