import Text from "./Text";
import React from "react";
import { View, Text as RnText } from "react-native";

function isText(child) {
  return (
    typeof child !== "object" ||
    (React.isValidElement(child) &&
      (child.type === Text || child.type === RnText))
  );
}

function Div({ children, ...props }) {
  const backgroundColors = {
    success: "#d1e7dd",
    danger: "#f8d7da",
    warning: "#fff3cd",
    info: "#cff4fc",
    default: "#00000000", // transparent
  };

  const chosenViewColor = backgroundColors[props.type || "default"];

  let CurrentText = <></>;
  const outputChildren = [];
  let isOnlyText = true;

  React.Children.forEach(children, (child, index) => {
    if (isText(child)) {
      CurrentText = (
        <Text key={`text-${index}`} {...props}>
          {CurrentText.props.children}
          {React.isValidElement(child)
            ? React.cloneElement(child, {
                ...props,
                ...child.props,
              })
            : child}
        </Text>
      );
    } else {
      isOnlyText = false;
      outputChildren.push(
        <React.Fragment key={`current-text-${index}`}>
          {CurrentText}
        </React.Fragment>
      );
      outputChildren.push(
        <React.Fragment key={`child-${index}`}>{child}</React.Fragment>
      );
      CurrentText = <></>;
    }
  });

  outputChildren.push(
    <React.Fragment key={`end-current-text`}>{CurrentText}</React.Fragment>
  );

  return isOnlyText ? (
    outputChildren
  ) : (
    <View
      {...props}
      style={[
        { backgroundColor: chosenViewColor, borderColor: chosenViewColor },
        props.style,
      ]}
    >
      {outputChildren}
    </View>
  );
}

export default Div;
