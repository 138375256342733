import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";
import Text from "../../presentational/Text";
import { useResponsiveUnits } from "../../../utilities/responsive";

function MaterialList(props) {
  const { workCenterDispatch, selectedOperation, user } = useStoreState(
    (state) => state
  );

  const { setMaterialListModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  const isNorthstar = user.kioskConfig?.taskComplete ?? false;

  const { vw } = useResponsiveUnits();

  const Element2 = ({ data, color }) => (
    <Text
      style={{
        fontSize: vw(0.95),
        textAlign: "center",
        alignSelf: "center",
        color,
        fontWeight: "bold",
      }}
    >
      {data.trim()}
    </Text>
  );

  const uniqueMaterialInventory = new Set();

  const list = workCenterDispatch.filter(
    (wcd) =>
      wcd.ProductionNbr === selectedOperation?.ProductionNbr &&
      wcd.OperationID === selectedOperation?.OperationID &&
      wcd.MaterialInventory &&
      !uniqueMaterialInventory.has(wcd.MaterialInventory) &&
      uniqueMaterialInventory.add(wcd.MaterialInventory)
  );

  return (
    <List
      width={
        isNorthstar
          ? [
              "12.5%",
              "12.5%",
              "12.5%",
              "12.5%",
              "12.5%",
              "12.5%",
              "12.5%",
              "12.5%",
            ]
          : ["18%", "28%", "8%", "9%", "8%", "5%", "8%", "9%", "7%"]
      }
      tableHead={
        isNorthstar
          ? [
              "Inventory ID",
              "Description",
              "Qty Required",
              "Total Required",
              "UOM",
              "Qty Issued",
              "Qty Remaining",
              "Batch Size",
            ]
          : [
              "Inventory ID",
              "Description",
              "Qty Required",
              "Total Required",
              "Qty On Hand",
              "UOM",
              "Qty Issued",
              "Qty Remaining",
              "Batch Size",
            ]
      }
      onPressCellEvents={Array(isNorthstar ? 8 : 9).fill(
        () => () => setMaterialListModalVisibleAction(true)
      )}
      list={list}
      tableRows={list.map((wcd) => {
        let color = "";
        const hasEnough =
          Number(wcd.MatTotQtyReq) <= Number(wcd.QtyHardAvailable);

        if (!hasEnough) {
          color = "red";
        } else if (
          hasEnough &&
          Number(wcd.MatQtyPicked) < Number(wcd.MatTotQtyReq)
        ) {
          color = "#b2b200";
        } else if (
          hasEnough &&
          Number(wcd.MatQtyPicked) >= Number(wcd.MatTotQtyReq)
        ) {
          color = "green";
        }

        return isNorthstar
          ? [
              <Element2 data={wcd.MaterialInventory} color={color} />,
              wcd.MaterialInventoryDesc,
              wcd.MatQtyReq ? parseFloat(wcd.MatQtyReq).toFixed(2) : "",
              wcd.MatTotQtyReq ? parseFloat(wcd.MatTotQtyReq).toFixed(2) : "",
              wcd.MatUOM,
              wcd.MatQtyPicked ? parseFloat(wcd.MatQtyPicked).toFixed(2) : "",
              wcd.MatQtyRemaining
                ? parseFloat(wcd.MatQtyRemaining).toFixed(2)
                : "",
              wcd.BatchSize ? parseFloat(wcd.BatchSize).toFixed(2) : "",
            ]
          : [
              <Element2 data={wcd.MaterialInventory} color={color} />,
              wcd.MaterialInventoryDesc,
              wcd.QtyHardAvailable
                ? parseFloat(wcd.QtyHardAvailable).toFixed(2)
                : "",
              wcd.MatQtyReq ? parseFloat(wcd.MatQtyReq).toFixed(2) : "",
              wcd.MatTotQtyReq ? parseFloat(wcd.MatTotQtyReq).toFixed(2) : "",
              wcd.MatUOM,
              wcd.MatQtyPicked ? parseFloat(wcd.MatQtyPicked).toFixed(2) : "",
              wcd.MatQtyRemaining
                ? parseFloat(wcd.MatQtyRemaining).toFixed(2)
                : "",
              wcd.BatchSize ? parseFloat(wcd.BatchSize).toFixed(2) : "",
            ];
      })}
      {...props}
    />
  );
}

export default MaterialList;
