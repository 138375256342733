const state = {
  docs: {
    docUrl: "",
    excelUrl: "",
    webViewActive: false,
    webViewURL: "",
    fileList: [],
    operationFileList: [],
    updatedDocNoteModalVisible: false,
  },
};

export default state;
