import React from "react";
import styled from "styled-components/native";
import { Text as RnText } from "react-native";

const SText = styled(RnText)`
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

function Text({ children, type, style, ...props }) {
  const types = {
    success: "#0a3622",
    danger: "#58151c",
    warning: "#664d03",
    info: "#055160",
    default: "#00205b",
  };

  return (
    <SText
      style={[
        {
          color: types[type || "default"],
        },
        style,
      ]}
      {...props}
    >
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              ...props,
              ...child.props,
              style: [style, child.props.style],
            })
          : child
      )}
    </SText>
  );
}

export default Text;
