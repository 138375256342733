import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";
import Text from "../../presentational/Text";
import { FontAwesome } from "@expo/vector-icons";
import * as DocumentPicker from "expo-document-picker";
import { useResponsiveTableSize } from "../../../hooks/responsive";
import { useRef, useEffect } from "react";

function OperationsList(props) {
  const {
    workCenterDispatch,
    currentDispatchObject,
    operationsLoading,
    dispatchLoading,
    opNbr,
    user,
    operationListModalVisible,
    operationSearchInput,
    selectedOperation,
  } = useStoreState((state) => state);

  const {
    selectOperation,
    setUpdatedDocNoteModalVisibleAction,
    upsertOperationFilesThunk,
    setUpdatedDocNote,
    setOperationListModalVisibleAction,
  } = useStoreActions((actions) => actions);

  const { responsiveTableBodySize, responsiveTableIconSize } =
    useResponsiveTableSize();

  const scrollViewRef = useRef(null);
  const itemRefs = useRef({});

  const isNorthstar = user.kioskConfig?.operationNotesAndFiles ?? false;

  const operationList = workCenterDispatch.filter((wcdItem, index, self) => {
    const formattedSearchInput = operationSearchInput.trim().toLowerCase();

    // Ensure uniqueness by checking if this key has already been seen
    const isUnique =
      self.findIndex(
        (item) =>
          item.ProductionNbr === wcdItem.ProductionNbr &&
          item.OperationID === wcdItem.OperationID
      ) === index;

    // Check if the OperationID or OpsDesc matches the search input
    const matchesSearch =
      wcdItem.OperationID?.trim().includes(formattedSearchInput) ||
      wcdItem.OpsDesc?.trim().toLowerCase().includes(formattedSearchInput);

    return (
      isUnique &&
      matchesSearch &&
      wcdItem.ProductionNbr === currentDispatchObject.ProductionNbr
    );
  });

  const Element = ({ data, dispatch }) => {
    const uniqueMaterialInventory = new Set();

    const materials = workCenterDispatch.filter((wcd) => {
      const isValidMaterial =
        wcd.ProductionNbr === dispatch.ProductionNbr &&
        wcd.OperationID === dispatch.OperationID &&
        wcd.MaterialInventory &&
        !uniqueMaterialInventory.has(wcd.MaterialInventory);

      isValidMaterial && uniqueMaterialInventory.add(wcd.MaterialInventory);

      return isValidMaterial;
    });

    const isRed = materials.some(
      (material) =>
        Number(material.MatTotQtyReq) > Number(material.QtyHardAvailable)
    );

    const isGreen =
      !isRed &&
      (!materials.length ||
        materials.every(
          (material) =>
            Number(material.MatTotQtyReq) <=
              Number(material.QtyHardAvailable) &&
            Number(material.MatQtyPicked) >= Number(material.MatTotQtyReq)
        ));

    let color = "";

    if (isRed) {
      color = "red";
    } else if (isGreen) {
      color = "green";
    } else {
      color = "#b2b200";
    }

    return (
      <Text
        style={{
          fontSize: responsiveTableBodySize + 2,
          textAlign: "center",
          alignSelf: "center",
          color,
          fontWeight: "bold",
        }}
      >
        {data}
      </Text>
    );
  };

  useEffect(() => {
    // Function to calculate the cumulative height dynamically
    const calculateHeightUntilChosenItem = async () => {
      const targetIndex = operationList.findIndex(
        (item) => item.OperationID === selectedOperation.OperationID
      );

      if (targetIndex !== -1) {
        let totalHeight = 0;

        // Loop through each item up to the targetIndex
        for (let i = 0; i < targetIndex; i++) {
          const itemRef = itemRefs.current[operationList[i].OperationID];

          if (itemRef) {
            await new Promise((resolve) => {
              itemRef.measure((x, y, width, height) => {
                totalHeight += height; // Add each item's height to the total
                resolve();
              });
            });
          }
        }

        // Scroll to the cumulative height
        scrollViewRef.current?.scrollTo({
          y: totalHeight,
          animated: true,
        });
      }
    };

    // Run the calculation after rendering
    calculateHeightUntilChosenItem();
  }, [selectedOperation.OperationID, dispatchLoading, operationsLoading]);

  return (
    <List
      itemRef={(dispatch) => (ref) =>
        (itemRefs.current[dispatch.OperationID] = ref)}
      scrollViewRef={scrollViewRef}
      list={operationList}
      onSelectRowInPopup={(dispatch) => () => {
        selectOperation(dispatch.OperationID);
        setOperationListModalVisibleAction(false);
      }}
      onPressCellEvents={Array(isNorthstar ? 8 : 6)
        .fill(false)
        .map((_, index) => (dispatch) => async () => {
          const primaryBoxIndx = isNorthstar ? 2 : 0;
          const isPrimaryBox =
            (!isNorthstar && index === 0) ||
            (isNorthstar && index === primaryBoxIndx);
          if (isPrimaryBox) {
            selectOperation(dispatch.OperationID);
            setOperationListModalVisibleAction(false);
          } else if (
            (!isPrimaryBox && isNorthstar && index > primaryBoxIndx) ||
            (!isPrimaryBox && !isNorthstar && index > primaryBoxIndx)
          ) {
            setOperationListModalVisibleAction(true);
          } else if (isNorthstar && index === 1) {
            selectOperation(dispatch.OperationID);
            const document = await DocumentPicker.getDocumentAsync({
              base64: true,
              type: "*/*",
            });

            const b64 = document.assets?.[0].uri.split(",")[1];

            upsertOperationFilesThunk({
              name: document.assets?.[0].name,
              uri: b64,
            });
          } else if (isNorthstar && index === 0) {
            setUpdatedDocNote(dispatch.OperationNote);
            selectOperation(dispatch.OperationID);
            operationListModalVisible &&
              setOperationListModalVisibleAction(false);
            setUpdatedDocNoteModalVisibleAction(true);
          }
        })}
      selectItem={operationList.findIndex(
        (item) => item.OperationID == opNbr.trim()
      )}
      loading={operationsLoading}
      width={
        isNorthstar
          ? ["3%", "3%", "17%", "15%", "15%", "15%", "16%", "16%"]
          : ["12%", "39%", "13%", "12%", "12%", "12%"]
      }
      tableHead={
        isNorthstar
          ? [
              "",
              "",
              "Number",
              "Operation Description",
              "Qty To Produce",
              "Qty Complete",
              "Qty Remaining",
              "Operation Status",
            ]
          : [
              "Number",
              "Operation Description",
              "Qty To Produce",
              "Qty Complete",
              "Qty Remaining",
              "Operation Status",
            ]
      }
      tableRows={operationList.map((item) => {
        if (isNorthstar) {
          return [
            <FontAwesome
              name="sticky-note"
              size={responsiveTableIconSize}
              color={item.OperationNote === "" ? "gray" : "#988200"}
            />,
            <FontAwesome
              name="paperclip"
              size={responsiveTableIconSize}
              color="gray"
            />,
            <Element data={item.OperationID} dispatch={item} />,
            item.OpsDesc,
            parseInt(item.QtytoProduce),
            parseInt(item.QtyComplete),
            parseInt(item.QtyRemaining),
            item.OpsStatus,
          ];
        } else {
          return [
            <Element data={item.OperationID} dispatch={item} />,
            item.OpsDesc,
            parseInt(item.QtytoProduce),
            parseInt(item.QtyComplete),
            parseInt(item.QtyRemaining),
            item.OpsStatus,
          ];
        }
      })}
      {...props}
    />
  );
}

export default OperationsList;
