import { thunk } from "easy-peasy";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  logout,
  Authenticate,
  AuthenticateACM,
  getAuthenticatedUserApiCall,
  GetWorkCentersAPICall,
  getAuthenticatedAcumaticaUserApiCall,
} from "../../services";
import Storage from "../../utilities/Storage";

import * as SecureStore from "expo-secure-store";
import { ERP_TOKEN_NAME, KIOSK_TOKEN_NAME } from "../../utilities/constants";

const loginThunks = {
  authenticate: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    try {
      const url = helpers.getState().apiBaseURL;
      const response = await Authenticate(url, payload);
      actions.setUser(response.data);
      actions.setUserIsLoggedIn(true);
      Storage.setItem(KIOSK_TOKEN_NAME, response.data.token);
      await actions.getAuthenticatedUserThunk();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(`Error: \n${error.response.data.message}`);
        error.response.data.message.includes(
          "Too many days" && AsyncStorage.clear()
        );
      } else {
        alert(`Error:\nThere was problem authenticating with Acumatica`);
      }
    }
    actions.setLoadingAction(false);
  }),
  logout: thunk(async (actions) => {
    actions.setLoadingAction(true);
    try {
      await logout();
      actions.setAcumaticaIsLoggedIn(false);
      Storage.removeItem(ERP_TOKEN_NAME);
    } catch (error) {}
    actions.setLoadingAction(false);
  }),
  authenticateACM: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const url = helpers.getState().apiBaseURL;
      const response = await AuthenticateACM(url, payload);
      Storage.setItem(ERP_TOKEN_NAME, response.data.erpToken);
      actions.setAcumaticaIsLoggedIn(true);
    } catch (error) {
      error.message
        ? alert(`Error:\n${error.message}`)
        : alert(
            error.response && error.response.data && error.response.data.message
          )
        ? alert(`Error:\n${error.response.data.message}`)
        : alert(`Error:\nThere was a problem authenticating with Acumatica`);
    }
    actions.setLoadingAction(false);
  }),
  getAuthenticatedUserThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getAuthenticatedUserApiCall();
      const { clientConfig, email, name, role } = response.data;
      actions.setUser({
        email,
        name,
        role,
        logo: clientConfig.logo,
        kioskConfig: clientConfig.kioskConfig,
      });
      actions.setUserIsLoggedIn(true);

      const acumaticaUserResponse =
        await getAuthenticatedAcumaticaUserApiCall();
      actions.setACMUser(acumaticaUserResponse.data);

      let erp = await Storage.getItem(ERP_TOKEN_NAME);
      if (erp != null) {
        actions.setAcumaticaIsLoggedIn(true);
        // await GetWorkCentersAPICall();
      }
    } catch (error) {
      // error.message
      // 	? alert(`Token expired. Please login.`)
      // 	: error.response && error.response.data && error.response.data.message
      // 	? alert(`Error:\n${error.response.data.message}`)
      // 	: null;
      if (error.message) {
        // actions.setUserIsLoggedIn(true);
        // actions.setAcumaticaIsLoggedIn(false);
        actions.setAlertThunk({
          type: "error",
          message: "Token expired. Please login.",
        });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // actions.setUserIsLoggedIn(true);
        // actions.setAcumaticaIsLoggedIn(false);
        actions.setAlertThunk({
          type: "error",
          message: `SECOND IF ${error.response.data.message}`,
        });
      } else {
        actions.setAlertThunk({
          type: "error",
          message: `Third IF There was a problem authenticating with Acumatica ${error.message}	`,
        });
      }
      // actions.setAlertThunk({
      // 	type: "error",
      // 	message: "Cannot authenticate user",
      // });
      // actions.setUserIsLoggedIn(false);
      // actions.setAcumaticaIsLoggedIn(false);
    }
    actions.setLoadingAction(false);
  }),
};
export default loginThunks;
