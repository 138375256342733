import React from "react";
import { TouchableOpacity, View } from "react-native";
import Card from "../../presentational/Card";
import ProductionOrderList from "../List/ProductionOrderList";
import DynamicText from "../../presentational/MyText";
import { useStoreActions } from "easy-peasy";

const DispatchCard = () => {
  const { setDispatchListModalVisibleAction } = useStoreActions(
    (actions) => actions
  );
  return (
    <TouchableOpacity
      onPress={() => {
        setDispatchListModalVisibleAction(true);
      }}
    >
      <View
        style={{
          ...styles.cardView,
          flexDirection: "column",
        }}
      >
        <DynamicText text={"Production Orders"} fontSize={15} />
        <View style={{ padding: "5px" }} />
        <Card>
          <ProductionOrderList />
        </Card>
      </View>
    </TouchableOpacity>
  );
};

export default DispatchCard;

const styles = {
  cardView: {
    alignSelf: "center",
    alignContent: "flex-start",
    width: "92%",
  },
};
