import Td from "./Td";
import Th from "./Th";
import Tr from "./Tr";
import Table from "./Table";

Table.th = Th;
Table.td = Td;
Table.tr = Tr;

export default Table;
