import { useStoreState, useStoreActions } from "easy-peasy";
import React, { useEffect } from "react";
import {
  Text,
  View,
  ActivityIndicator,
  Platform,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { Cell, Row, Table, TableWrapper } from "react-native-table-component";

const Docs = ({ navigation }) => {
  const {
    user,
    modalLoading,
    docs: {
      fileList,
      operationFileList,
      docUrl,
      excelUrl,
      webViewActive,
      webViewURL,
    },
  } = useStoreState((state) => state);

  const { getFileDetail, selectFile } = useStoreActions((actions) => actions);

  useEffect(() => {
    fileList == [] && getFileDetail();
    webViewActive && Platform.OS !== "web" && navigation.push("Viewer");
  }, [webViewActive, webViewURL, fileList, excelUrl, docUrl]);

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const _handlePressButtonAsync = async (item) => {
    const payload = {
      item: item,
      id: item.id,
      token: user && user.token,
    };
    await selectFile(payload);
  };

  const element = (item, file) => (
    <TouchableOpacity onPress={() => _handlePressButtonAsync(file)}>
      <Text
        style={{
          fontSize: 20,
          textAlign: "center",
          alignSelf: "center",
          padding: 10,
          textDecorationLine: "underline",
          color: "blue",
        }}
      >
        {item.includes(".xlsx") ? (
          <a
            href={URL.createObjectURL(
              b64toBlob(file.fileDetails, "application/octet-stream")
            )}
            download={
              URL.createObjectURL(
                b64toBlob(file.fileDetails, "application/octet-stream")
              ) + ".xlsx"
            }
            onclick="window.open(this.href); return false;"
            onKeyPress="window.open(this.href); return false;"
          >
            {item.split("\\").pop()}
          </a>
        ) : item.includes(".docx") ? (
          <a
            href={URL.createObjectURL(
              b64toBlob(file.fileDetails, "application/octet-stream")
            )}
            download={
              URL.createObjectURL(
                b64toBlob(file.fileDetails, "application/octet-stream")
              ) + ".docx"
            }
            onclick="window.open(this.href); return false;"
            onKeyPress="window.open(this.href); return false;"
          >
            {item.split("\\").pop()}
          </a>
        ) : (
          item.split("\\").pop()
        )}
      </Text>
    </TouchableOpacity>
  );

  return (
    <View
      style={{
        alignSelf: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "#D2D4D7",

        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {modalLoading ? (
        <ActivityIndicator
          size="large"
          style={{
            alignSelf: "center",
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <>
          <>
            <View
              style={{
                width: "92%",
                height: "90%",
                marginBottom: "5%",

                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 25,
                  margin: 10,
                  marginRight: "auto",
                }}
              >
                Files
              </Text>
              <ScrollView
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                  alignSelf: "center",
                }}
              >
                <Table
                  borderStyle={{
                    borderWidth: 1,
                    borderColor: "#C1C0B9",
                  }}
                >
                  <Row
                    data={["Production Orders", "Operations"]}
                    widthArr={["50%", "50%"]}
                    style={{
                      flexDirection: "row",
                      alignSelf: "flex-start",
                      alignItems: "center",
                      marginRight: "auto",
                      width: "100%",
                      backgroundColor: "#F5F7F8",
                    }}
                    textStyle={{
                      textAlign: "center",
                      padding: 15,
                      fontSize: 22,
                    }}
                  />
                  {fileList && fileList.length > 0 ? (
                    fileList
                      .map((item, index) => [
                        item.filename,
                        operationFileList[index]?.filename,
                      ])
                      .map((rowData, index) => (
                        <TableWrapper
                          key={index}
                          style={{
                            flexDirection: "row",
                            backgroundColor: "#E7E6E1",
                          }}
                        >
                          {rowData.map((cellData, cellIndex) => (
                            <Cell
                              key={cellIndex}
                              data={
                                cellData
                                  ? element(
                                      cellData,
                                      cellIndex === 0
                                        ? fileList[index]
                                        : operationFileList[index]
                                    )
                                  : ""
                              }
                              style={{
                                width: "50%",
                                backgroundColor:
                                  index % 2 === 0 ? "#E8F0FE" : "#FBFCFF",
                                padding: 20,
                              }}
                              textStyle={{
                                fontSize: 20,
                                textAlign: "center",
                                alignSelf: "center",
                              }}
                            />
                          ))}
                        </TableWrapper>
                      ))
                  ) : (
                    <Text
                      style={{
                        fontSize: 20,
                        textAlign: "center",
                        alignSelf: "center",
                        padding: 10,
                      }}
                    >
                      No Files Found
                    </Text>
                  )}
                </Table>
              </ScrollView>
            </View>
          </>
        </>
      )}
    </View>
  );
};

export default Docs;
