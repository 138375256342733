import React from "react";
import { TouchableOpacity, View } from "react-native";
import Card from "../../presentational/Card";
import MaterialList from "../List/MaterialList";
import StepsList from "../List/StepsList";
import ToolsList from "../List/ToolsList";
import { useStoreActions, useStoreState } from "easy-peasy";
import MaterialButtonGroup from "../ButtonGroup/MaterialButtonGroup";

const MaterialCard = () => {
  const { materialActive, toolsActive, stepsActive } = useStoreState(
    (state) => state
  );

  const {
    setMaterialListModalVisibleAction,
    setStepListModalVisibleAction,
    setToolListModalVisibleAction,
  } = useStoreActions((actions) => actions);

  return (
    <View
      style={{
        ...styles.cardView,
        flexDirection: "column",
      }}
    >
      <MaterialButtonGroup />
      <View style={{ padding: "5px" }} />

      <TouchableOpacity
        onPress={() => {
          if (toolsActive) setToolListModalVisibleAction(true);
          else if (stepsActive) setStepListModalVisibleAction(true);
          else setMaterialListModalVisibleAction(true);
        }}
      >
        <Card>
          {toolsActive ? (
            <ToolsList />
          ) : stepsActive ? (
            <StepsList />
          ) : materialActive ? (
            <MaterialList />
          ) : (
            <MaterialList />
          )}
        </Card>
      </TouchableOpacity>
    </View>
  );
};

export default MaterialCard;

const styles = {
  cardView: {
    alignSelf: "center",
    alignContent: "flex-start",

    width: "92%",
  },
};
