import React from "react";
import CustomModal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import OperationsList from "../List/OperationsList";
import SearchBar from "../../presentational/SearchBar";
import Gap from "../../presentational/Gap";
import { useResponsiveModalSize } from "../../../hooks/responsive";

const OperationListModal = () => {
  const { operationListModalVisible, operationSearchInput } = useStoreState(
    (state) => state
  );

  const { setOperationListModalVisibleAction, setOperationSearchInputAction } =
    useStoreActions((actions) => actions);

  const { responsiveModalWidth, responsiveModalHeight } =
    useResponsiveModalSize();

  return (
    <CustomModal
      isVisible={operationListModalVisible}
      onClose={() => {
        setOperationListModalVisibleAction(false);
        setOperationSearchInputAction("");
      }}
      title="Operations"
      style={{ width: responsiveModalWidth }}
    >
      <SearchBar
        value={operationSearchInput}
        onChangeText={(input) => setOperationSearchInputAction(input)}
        placeholder="Search by Operation Number and Operation Description"
      />
      <Gap />
      <OperationsList isShownInModal maxHeight={responsiveModalHeight} />
    </CustomModal>
  );
};

export default OperationListModal;
