import { thunk } from "easy-peasy";
import {
  CompleteTaskAPICall,
  CreateLaborAPICall,
  GetLaborLineItemsAPICall,
  ReleaseLaborAPICall,
} from "../../services";
import moment from "moment";
import {
  calculateNorthStarLaborTimeDifference,
  roundToNearest15Minutes,
} from "../../utilities/time";

const thunks = {
  createLaborThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const isNorthstar =
        helpers.getState().user.kioskConfig?.roundTimeTo15Minutes ?? false;
      const i = helpers.getState().labor.input.Details[0];
      const startTime = isNorthstar
        ? roundToNearest15Minutes(i.StartTime.value)
        : i.StartTime.value;
      const endTime = isNorthstar
        ? roundToNearest15Minutes(i.EndTime.value)
        : i.EndTime.value;
      let difference = isNorthstar
        ? calculateNorthStarLaborTimeDifference(startTime, endTime)
        : moment
            .utc(moment(endTime, "h:mm A").diff(moment(startTime, "h:mm A")))
            .format("HH:mm");

      isNorthstar && difference === "00:00" && (difference = "00:01");

      if (helpers.getState().labor.laborLineItems.length == 0) {
        await CreateLaborAPICall({
          ...helpers.getState().labor.input,
          Date: {
            value: moment(helpers.getState().labor.input.Date.value).format(
              "MM/DD/YYYY"
            ),
          },
          Details: [
            {
              note: {
                value: i.note.value,
              },
              LaborType: {
                value: "Direct",
              },
              ProductionNbr: {
                value: helpers.getState().selectedOperation.ProductionNbr,
              },
              OperationNbr: {
                value: helpers.getState().selectedOperation.OperationID.trim(),
              },
              EmployeeID: {
                value: i.EmployeeID.value,
              },
              LaborTime: {
                value: i.StartTime.value == "" ? i.LaborTime.value : difference,
              },
              Location: {
                value: helpers.getState().selectedOperation.LocationID,
              },
              StartTime: {
                value: startTime === "Invalid date" ? "" : startTime,
              },
              EndTime: {
                value: endTime === "Invalid date" ? "" : endTime,
              },
              Shift: {
                value: "0001",
              },
              INDocType: {
                value: "Receipt",
              },
              InventoryID: {
                value: helpers.getState().selectedOperation.InventoryID,
              },
              OrderType: {
                value: helpers.getState().selectedOperation.OrderType_2,
              },
              QtyisScrap: {
                value: false,
              },
            },
          ],
        });
      } else {
        await CreateLaborAPICall({
          ...helpers.getState().labor.input,
          Date: {
            value: moment(helpers.getState().labor.input.Date.value).format(
              "MM/DD/YYYY"
            ),
          },
          BatchNbr: {
            value: helpers.getState().labor.laborLineItems[0].BatchNbr,
          },
          Details: [
            {
              note: {
                value: i.note.value,
              },
              LaborType: {
                value: "Direct",
              },
              ProductionNbr: {
                value: helpers.getState().selectedDispatch,
              },
              OperationNbr: {
                value: helpers.getState().opNbr.trim(),
              },
              EmployeeID: {
                value: i.EmployeeID.value,
              },
              LaborTime: {
                value: i.StartTime.value == "" ? i.LaborTime.value : difference,
              },
              Location: {
                value: helpers.getState().selectedOperation.LocationID,
              },
              StartTime: {
                value: startTime === "Invalid date" ? "" : startTime,
              },
              EndTime: {
                value: endTime === "Invalid date" ? "" : endTime,
              },
              Shift: {
                value: "1",
              },
              INDocType: {
                value: "Receipt",
              },
              InventoryID: {
                value: helpers.getState().selectedOperation.InventoryID,
              },
              OrderType: {
                value: helpers.getState().selectedOperation.OrderType_2,
              },
              QtyisScrap: {
                value: false,
              },
            },
          ],
        });
      }
      actions.getLaborLineItemsThunk();

      actions.setLaborInputAction({
        ...helpers.getState().labor.input,

        Date: {
          value: moment().format("MM/DD/YYYY"),
        },
        Details: [
          {
            note: {
              value: "",
            },
            LaborType: {
              value: "Direct",
            },
            ProductionNbr: {
              value: helpers.getState().selectedDispatch,
            },
            OperationNbr: {
              value: helpers.getState().opNbr.trim(),
            },
            EmployeeID: {
              value: "",
            },
            LaborTime: {
              value: "",
            },
            StartTime: {
              value: "",
            },
            EndTime: {
              value: "",
            },
            Shift: {
              value: "1",
            },
            INDocType: {
              value: "Receipt",
            },
            InventoryID: {
              value: helpers.getState().selectedOperation.InventoryID,
            },
            OrderType: {
              value: "WO",
            },
            QtyisScrap: {
              value: false,
            },
          },
        ],
      });

      actions.setLaborModalVisible(false);
      actions.setAlertThunk({
        type: "success",
        message: "Labor created successfully.",
      });
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      alert(errorMessage);
      actions.setAlertThunk({
        type: "error",
        message: errorMessage,
      });
    }
    actions.setLoadingAction(false);
  }),

  getLaborLineItemsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await GetLaborLineItemsAPICall(
        helpers.getState().selectedDispatch,
        helpers.getState().opNbr.trim(),
        payload ?? new Date()
      );
      console.log(response);

      actions.setLaborLineItemsAction(response.data.data);

      // actions.setLaborLineItemsAction(response.data.data);
    } catch (error) {
      actions.setAlertThunk({
        type: "error",
        message: "Unable to get labor line items. Please try again.",
      });
    }
    actions.setLoadingAction(false);
  }),

  processTimeThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    try {
      await CreateLaborAPICall(payload);
      actions.getLaborLineItemsThunk();
      actions.setAlertThunk({
        type: "success",
        message: "Time updated successfully.",
      });
      actions.setLaborModalVisible(false);
      actions.setLaborNoteModalVisibleAction(false);
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      alert(errorMessage);
      actions.setAlertThunk({
        type: "error",
        message: errorMessage,
      });
    }
    actions.setLoadingAction(false);
  }),

  completeTaskThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    try {
      await CompleteTaskAPICall({
        entity: {
          ProductionNbr: {
            value: helpers.getState().selectedOperation.ProductionNbr,
          },
          OperationID: {
            value: helpers.getState().selectedOperation.OperationID,
          },
          OrderType: {
            value: helpers.getState().selectedOperation.OrderType,
          },
          Approver: {
            value: helpers.getState().user.kioskConfig.taskApproverNumber,
          },
        },
      });
      actions.getLaborLineItemsThunk();
      actions.setLaborModalVisible(false);
      actions.setAlertThunk({
        type: "success",
        message: "Task updated successfully.",
      });
    } catch (error) {
      const message = error.response ? error.response.data : error.message;

      alert(message);
      actions.setLaborModalVisible(false);
      actions.setAlertThunk({
        type: "error",
        message: message,
      });
    }
    actions.setLoadingAction(false);
  }),

  releaseLaborThunk: thunk(async (actions, guid, helpers) => {
    actions.setLoadingAction(true);
    try {
      await ReleaseLaborAPICall(guid);
      actions.setAlertThunk({
        type: "success",
        message: "Labor released successfully.",
      });
    } catch (error) {
      alert("Labor was not released. Please try again.");
      console.log(error);
      actions.setAlertThunk({
        type: "error",
        message: "Labor was not released. Please try again.",
      });
    }
    actions.setLoadingAction(false);
  }),
};
export default thunks;
