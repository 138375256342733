const workCenterModel = {
  workCenters: [],
  workCenterDispatch: [],
  selectedDispatch: [],
  operations: [],

  currentDispatchObject: {},
  selectedOperation: {},
  dispatchLoading: false,
  operationsLoading: false,
  modalLoading: false,
  moveActive: false,
  employees: [],
  opNbr: "",

  materialActive: true,
  stepsActive: false,
  toolsActive: false,

  workCenterString: "",

  operationListModalVisible: false,
  materialListModalVisible: false,
  toolListModalVisible: false,
  stepListModalVisible: false,
  dispatchListModalVisible: false,

  productionOrderSearchInput: "",
  productionOrderYPositionToScrollTo: 0,
  operationSearchInput: "",
  operationYPositionToScrollTo: 0,
};

export default workCenterModel;
