import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../../presentational/Button";
import Text from "../../presentational/Text";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import { useResponsiveUnits } from "../../../utilities/responsive";

export default function AreYouSureModal({ onSubmit }) {
  const { labor } = useStoreState((state) => state);

  const { setAreYouSureModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  const handleOnSubmit = () => {
    onSubmit();
    setAreYouSureModalVisibleAction(false);
  };

  const { vw } = useResponsiveUnits();

  return (
    <CustomModal
      style={{ width: vw(30) }}
      width="50%"
      height="50%"
      title="Confirmation"
      onClose={() => setAreYouSureModalVisibleAction(false)}
      isVisible={labor.areYouSureModalVisible}
    >
      <Text style={{ fontSize: 20, fontWeight: "bold", alignSelf: "center" }}>
        {"Are you sure you want to make this change?"}
      </Text>
      <Row>
        <Col xs={12} sm={6}>
          <Button
            color="#ab2328"
            fullWidth
            style={{ marginTop: 20 }}
            onPress={() => {
              setAreYouSureModalVisibleAction(false);
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button fullWidth style={{ marginTop: 20 }} onPress={handleOnSubmit}>
            Confirm
          </Button>
        </Col>
      </Row>
    </CustomModal>
  );
}
