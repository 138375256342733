import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../../presentational/Button";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";
import Gap from "../../presentational/Gap";
import { useResponsiveModalSize } from "../../../hooks/responsive";

export default function ProductionOrderNotesModal() {
  const { updatedDocNoteModalVisible, updatedDocNote } = useStoreState(
    (state) => state.docs
  );

  const {
    setUpdatedDocNoteModalVisibleAction,
    setUpdatedDocNote,
    upsertOperationNoteThunk,
  } = useStoreActions((actions) => actions);

  const { responsiveModalWidth } = useResponsiveModalSize();

  return (
    <CustomModal
      style={{ width: responsiveModalWidth }}
      title="Notes"
      onClose={() => setUpdatedDocNoteModalVisibleAction(false)}
      isVisible={updatedDocNoteModalVisible}
    >
      <Col>
        <Input
          multiline
          numberOfLines={4}
          value={updatedDocNote}
          onChangeText={(text) => {
            setUpdatedDocNote(text);
          }}
        />
      </Col>
      <Gap />
      <Row>
        <Col>
          <Button fullWidth onPress={() => upsertOperationNoteThunk()}>
            Update
          </Button>
        </Col>
      </Row>
    </CustomModal>
  );
}
