import { thunk } from "easy-peasy";
import { CreateMoveAPICall } from "../../services";

const thunks = {
  createMoveThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    try {
      await CreateMoveAPICall({
        ...helpers.getState().move.input,
        Details: [
          {
            ...helpers.getState().move.input.Details[0],
            ProductionNbr: {
              value: helpers.getState().selectedOperation.ProductionNbr,
            },
            InventoryID: {
              value: helpers.getState().selectedOperation.InventoryID,
            },
            OperationNbr: {
              value: helpers.getState().selectedOperation.OperationID,
            },
            Location: {
              value: helpers.getState().selectedOperation.LocationID,
            },
          },
        ],
      });

      actions.selectWorkCenterThunk(helpers.getState().desiredWorkCenter);
      actions.setMoveModalVisible(false);
      actions.setAlertThunk({
        type: "success",
        message: "Move Created Successfully!",
      });
    } catch (error) {
      console.log(error);

      const message = error.response ? error.response.data : error.message;

      alert(message);
      actions.setAlertThunk({
        type: "error",
        message: message,
      });
    }
    actions.setLoadingAction(false);
  }),
};
export default thunks;
