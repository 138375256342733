import React from "react";
import CustomModal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import MaterialList from "../List/MaterialList";
import { useResponsiveModalSize } from "../../../hooks/responsive";

const MaterialListModal = () => {
  const { materialListModalVisible } = useStoreState((state) => state);

  const { setMaterialListModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  const { responsiveModalWidth, responsiveModalHeight } =
    useResponsiveModalSize();

  return (
    <CustomModal
      isVisible={materialListModalVisible}
      onClose={() => setMaterialListModalVisibleAction(false)}
      title="Material"
      style={{ width: responsiveModalWidth }}
    >
      <MaterialList isShownInModal maxHeight={responsiveModalHeight} />
    </CustomModal>
  );
};

export default MaterialListModal;
