import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";

function StepsList(props) {
  const { workCenterDispatch, selectedOperation } = useStoreState(
    (state) => state
  );

  const { setStepListModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  const uniqueStep = new Set();

  const list = workCenterDispatch.filter(
    (wcd) =>
      wcd.ProductionNbr === selectedOperation?.ProductionNbr &&
      wcd.OperationID === selectedOperation?.OperationID &&
      wcd.StepLineID &&
      !uniqueStep.has(wcd.StepLineID) &&
      uniqueStep.add(wcd.StepLineID)
  );

  return (
    <List
      tableHead={["Description", "Line Order"]}
      onPressCellEvents={Array(2).fill(
        () => () => setStepListModalVisibleAction(true)
      )}
      list={list}
      tableRows={list.map((wcd) => [wcd.StepDesc, wcd.LineOrder])}
      {...props}
    />
  );
}

export default StepsList;
