import styled from "styled-components/native";
import Div from "../Div";
import React from "react";

const Container = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function CenterV2({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

export default CenterV2;
