import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";
import { TouchableOpacity } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import Input from "../../presentational/Input";
import moment from "moment";
import Button from "../../presentational/Button";
import { useResponsiveTableSize } from "../../../hooks/responsive";

function useCenterStyle() {
  const { responsiveTableBodySize } = useResponsiveTableSize();
  return {
    alignSelf: "center",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: responsiveTableBodySize,
  };
}

function LaborList({ listHeight = 0.75, ...props }) {
  const {
    labor: { laborLineItems },
    operationsLoading,
    user,
  } = useStoreState((state) => state);
  const {
    setNewLaborTimeAction,
    setNewStartTimeAction,
    setNewEndTimeAction,
    setAreYouSureModalVisibleAction,
    setSelectedLaborIndexAction,
    setLaborNoteModalVisibleAction,
  } = useStoreActions((actions) => actions);

  const { responsiveTableIconSize, responsiveTableBodySize } =
    useResponsiveTableSize();

  const centerStyle = useCenterStyle();

  const isNorthStar = user.kioskConfig?.roundTimeTo15Minutes ?? false;

  const Element = ({ data, index }) => (
    <Button
      disabled={
        laborLineItems[index].StartTime == null &&
        laborLineItems[index].EndTime == null &&
        laborLineItems[index].LaborTime == laborLineItems[index].newLaborTime
          ? true
          : laborLineItems[index].StartTime == null &&
            laborLineItems[index].EndTime == null &&
            laborLineItems[index].LaborTime !==
              laborLineItems[index].newLaborTime
          ? false
          : laborLineItems[index].LaborTime == "00:01"
          ? false
          : moment(laborLineItems[index].StartTime).format("h:mm A") ==
              laborLineItems[index].newStartTime &&
            moment(laborLineItems[index].EndTime).format("h:mm A") ==
              laborLineItems[index].newEndTime
          ? true
          : moment(laborLineItems[index].StartTime).format("h:mm A") !==
            laborLineItems[index].newStartTime
          ? false
          : moment(laborLineItems[index].EndTime).format("h:mm A") !==
            laborLineItems[index].newEndTime
          ? false
          : true
      }
      color={"#00205B"}
      fullWidth
      onPress={() => {
        setSelectedLaborIndexAction(index);
        setAreYouSureModalVisibleAction(true);
      }}
      titleStyle={{ fontSize: responsiveTableBodySize }}
    >
      {laborLineItems[index].StartTime == null &&
      laborLineItems[index].EndTime == null &&
      laborLineItems[index].LaborTime !== laborLineItems[index].newLaborTime
        ? "Enter"
        : moment(laborLineItems[index].StartTime).format("h:mm A") ==
            laborLineItems[index].newStartTime &&
          moment(laborLineItems[index].EndTime).format("h:mm A") ==
            laborLineItems[index].newEndTime
        ? "Clock Out"
        : moment(laborLineItems[index].StartTime).format("h:mm A") !==
          laborLineItems[index].newStartTime
        ? "Enter"
        : moment(laborLineItems[index].EndTime).format("h:mm A") !==
          laborLineItems[index].newEndTime
        ? "Enter"
        : "Clock Out"}
    </Button>
  );

  return (
    <List
      clickableBoxes={[false, false, false, false, false, false, false]}
      fontSize={responsiveTableBodySize}
      onPress={(data, index) => {}}
      pageSize={5}
      width={["3%", "35%", "10%", "13%", "13%", "13%", "13%"]}
      listHeight={listHeight}
      pagination={true}
      loading={operationsLoading}
      tableHead={[
        "",
        "Employee ID",
        "Operation Number",
        "Start Time",
        "End Time",
        "Labor Time",
        "Clock Out",
      ]}
      tableRows={laborLineItems.map((item, index) => [
        <TouchableOpacity
          style={{
            width: "100%",
            height: "100%",
          }}
          onPress={() => {
            setSelectedLaborIndexAction(index);
            setLaborNoteModalVisibleAction(true);
          }}
        >
          <FontAwesome
            name="sticky-note"
            size={responsiveTableIconSize}
            color="gray"
          />
        </TouchableOpacity>,

        item.EmployeeID + " - " + item.EmployeeName,
        item.OperationID,

        <Input
          inputContainerStyle={centerStyle}
          inputStyle={centerStyle}
          style={centerStyle}
          textAlign={"center"}
          value={item.newStartTime == "Invalid date" ? "" : item.newStartTime}
          placeholder="Start Time"
          onChangeText={(input) =>
            setNewStartTimeAction({
              index,
              input,
            })
          }
          editable={true}
        />,
        item.LaborTime == "00:01" ? (
          <></>
        ) : (
          <Input
            inputContainerStyle={centerStyle}
            inputStyle={centerStyle}
            style={{
              ...centerStyle,
              color: "#00205b",
            }}
            value={item.newEndTime == "Invalid date" ? "" : item.newEndTime}
            placeholder="End Time"
            editable={true}
            onChangeText={(val) =>
              setNewEndTimeAction({
                index,
                input: val,
              })
            }
            onBlur={() => {
              const difference = moment
                .utc(
                  moment(item.newEndTime, "h:mm A").diff(
                    moment(item.newStartTime, "h:mm A")
                  )
                )
                .format("HH:mm");
              if (difference !== "Invalid date") {
                setNewLaborTimeAction({
                  index,
                  input: difference,
                });
              }
            }}
          />
        ),
        item.StartTime == null && item.EndTime == null ? (
          <Input
            inputContainerStyle={centerStyle}
            inputStyle={centerStyle}
            style={centerStyle}
            value={item.newLaborTime == "" ? item.LaborTime : item.newLaborTime}
            editable={true}
            onChangeText={(val) => {
              const numericText = val.replace(/[^0-9]/g, "").slice(-4); // Take only the last 4 digits
              const seconds = numericText.slice(-2).padStart(2, "0");
              const minutes = numericText.slice(0, -2).padStart(2, "0");
              setNewLaborTimeAction({
                index,
                input: !val ? "" : `${minutes}:${seconds}`,
              });
            }}
          />
        ) : item.LaborTime == "00:01" ? (
          "00:00"
        ) : (
          item.LaborTime
        ),

        <Element
          data={item.checkmark + " " + item.OperationNbr}
          index={index}
        />,
      ])}
      {...props}
    />
  );
}

export default LaborList;
