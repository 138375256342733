import styled from "styled-components/native";
import Div from "../Div";
import React, { useContext } from "react";
import TableContext from "./TableContext";

const Container = styled(Div)`
  flex: 1;
  border: ${(props) => (props.border ? "1px solid #dee2e6" : "0")};
`;

function Td({ children, ...props }) {
  const { border } = useContext(TableContext);
  return (
    <Container border={border} {...props}>
      {children}
    </Container>
  );
}

export default Td;
