import React from "react";
import CustomModal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useResponsiveModalSize } from "../../../hooks/responsive";
import ToolsList from "../List/ToolsList";

const ToolListModal = () => {
  const { toolListModalVisible } = useStoreState((state) => state);

  const { setToolListModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  const { responsiveModalWidth, responsiveModalHeight } =
    useResponsiveModalSize();

  return (
    <CustomModal
      isVisible={toolListModalVisible}
      onClose={() => setToolListModalVisibleAction(false)}
      title="Tool"
      style={{ width: responsiveModalWidth }}
    >
      <ToolsList isShownInModal maxHeight={responsiveModalHeight} />
    </CustomModal>
  );
};

export default ToolListModal;
